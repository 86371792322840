export const ALEJANDRA = 'ALEJANDRA';
export const ANGIE = 'ANGIE';
export const QA_ENVIRONMENT = 'qa';
export const PROD_ENVIRONMENT = 'prod';
export const LOCAL_ENVIRONMENT = 'local';
export const DEV_ENVIRONMENT = 'dev';
export const STAGE_ENVIRONMENT = 'stage';

export const milliseconds_call = 1000;

export const API_URL = {
  prod: 'https://api.vitawallet.io/api',
  stage: 'https://api.stage.vitawallet.io/api',
  qa: 'https://api.qa.vitawallet.io/api',
  dev: 'http://192.168.10.9:3000/api',
  local: 'http://localhost:3000/api',
};

export const WEB_APP_URL = {
  prod: 'https://app.vitawallet.io',
  stage: 'https://stage.vitawallet.io',
  qa: 'https://qa.vitawallet.io',
  dev: '',
  local: 'http://localhost:3001',
};

// time session in minutes
export const SESSION_TIME = {
  prod: 10,
  stage: 10,
  qa: 60,
  dev: 1440,
  local: 1440,
};

export const EXTERNAL_LINKS = {
  zip_code: 'https://www.whatismyzip.com/',
};

export const ENDPOINTS = {
  bankAccounts: {
    searhBeneficiary: {
      url: '/bank_accounts/search',
      method: 'post',
    },
  },
  auth: {
    register: {
      url: '/auth',
      method: 'post',
    },
    logIn: {
      url: '/auth/sign_in',
      method: 'post',
    },
    logInFromPin: {
      url: '/mobile/sign_in',
      method: 'post',
    },
    recoverPin: {
      url: '/mobile/recover_pin',
      method: 'post',
    },
    recoverPassword: {
      url: '/auth/password',
      method: 'post',
    },
    recoverPasswordFromRequest: {
      url: '/auth/password',
      method: 'put',
    },
    logOut: {
      url: '/auth/sign_out',
      method: 'DELETE',
    },
  },
  profile: {
    updateBankAccount: {
      url: '/users/banks_accounts/:id',
      method: 'put',
    },
    deleteBankAccount: {
      url: '/users/banks_accounts/:id',
      method: 'delete',
    },
    createBankAccount: {
      url: '/users/banks_accounts',
      method: 'post',
    },
    // getBankAccount: {
    //   url: '/users/banks_accounts',
    //   method: 'get',
    // },
    // getFavoriteAccounts: {
    //   url: '/users/favorite_accounts',
    //   method: 'get',
    // },
    createFavoriteAccount: {
      url: '/users/favorite_accounts',
      method: 'post',
    },
    updateFavoriteAccount: {
      url: '/users/favorite_accounts/:id',
      method: 'put',
    },
    deleteFavoriteAccount: {
      url: '/users/favorite_accounts/:id',
      method: 'delete',
    },
    startVerification: {
      url: '/users/start_verification',
      method: 'post',
    },
    getBusinessCredentials: {
      method: 'post',
      url: '/users/business_credentials_from_token',
    },
    ipsWhiteList: {
      method: 'get',
      url: '/users/ips_whitelist_business',
    },
    sendCredential: {
      method: 'get',
      url: '/users/send_business_credentials',
    },
    saveIps: {
      method: 'post',
      url: '/users/save_ips_business',
    },
    deleteCreditCard: {
      method: 'delete',
      url: '/credits_card/:card',
    },
    generatePaymentLink: {
      method: 'post',
      url: '/paybutton/generate',
    },
    findUserFromEmailOrWallet: (params) => ({
      method: 'get',
      url: `/users/find?${Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')}`,
    }),
    saveProfileBank: {
      method: 'post',
      url: '/profile/account_bank',
    },
    sendLimitRequest: {
      method: 'get',
      url: '/profile/request_daily_limit_extension',
    },
    sendLimitRequestTransactions: {
      method: 'get',
      url: '/profile/request_limit_extension?limit_type=:limit_type&limit_duration=:limit_duration',
    },
    confirmCodeDoubleAuthetication: {
      method: 'post',
      url: '/users/confirm_second_factor',
    },
    activateCodeDoubleAuthentication: {
      method: 'put',
      url: '/users/activate_second_factor',
    },
    deactivateCodeDoubleAuthentication: {
      method: 'put',
      url: '/users/deactivate_second_factor',
    },
    sendCodeDoubleAuthentication: {
      method: 'post',
      url: '/users/resend_second_factor_token',
    },
    getProfile: {
      url: '/profile',
      method: 'get',
    },
    sendVerification: {
      method: 'put',
      url: '/profile',
    },
    sendVerificationBusiness: {
      method: 'put',
      url: '/users/update_business',
    },
    activatePinCode: {
      method: 'put',
      url: '/profile/activate_pin',
    },
    activateAutomaticExchange: {
      method: 'put',
      url: '/users/activate_automatic_exchange',
    },
    deactivateAutomaticExchange: {
      method: 'put',
      url: '/users/deactivate_automatic_exchange',
    },
    verificationStatus: {
      url: '/users/verification_status',
      method: 'get',
    },
    requestBiometricVerification: {
      url: '/users/biometric_request',
      method: 'put',
    },
    createBtcAddress: {
      method: 'post',
      url: '/profile/create_btc_address',
    },
    changeStatusTruspillot: {
      url: '/users/status_valoration_transaction',
      method: 'post',
    },
    authConfirmCodeDoubleAuthetication: {
      method: 'post',
      url: '/auth/second_factors',
    },
    authResendCodeDoubleAuthetication: {
      method: 'post',
      url: '/auth/second_factors/resend',
    },
    firstTransaction: {
      method: 'post',
      url: '/users/set_first_transactions'
    },
    getBiometricStatus: {
      method: 'get',
      url: '/users/biometric_status',
    },
    getLimitsUser: {
      method: 'get',
      url: '/users/get_limits',
    },
    RequestUpdateLimits: (data) => ({
      url: '/users/request_update_limits',
      method: 'post',
      data,
    }),
    getOccupations: {
      method: 'get',
      url: '/occupations'
    },
    handleSetDefaultCurrency: {
      method: 'put',
      url: '/users/set_default_currency'
    },
    getUserBalances: {
      method: 'get',
      url: '/users/get_user_balances'
    },
    findFrecuentAccount: (params) => ({
      method: 'get',
      url: `/users/favorite_accounts?${Object.keys(params).map(key => `q[${key}]=${encodeURIComponent(params[key])}`).join('&')}`,
    }),
    getFrecuentAccount: {
      method: 'get',
      url: '/users/favorite_accounts'
    },
  },
  prices: {
    get: {
      url: '/users/get_crypto_multi_prices',
      method: 'get',
    },
    getTransferPrices: {
      url: '/users/get_fiat_multi_prices',
      method: 'get',
    },
  },
  settings: {
    countries: {
      url: '/birth_countries',
      method: 'get',
    },
    favorite_countries: {
      url: '/users/favorites_countries',
      method: 'get',
    },
    provinces: {
      url: '/countries/:country/states',
      method: 'get',
    },
    cities: (stateID) => ({
      url: `/countries/${stateID}/cities`,
      method: 'get',
    }),
    lada_codes: (country) => ({
      url: `/countries/${country}/lada`,
      method: 'get',
    }),
    states: (state) => ({
      url: `/city/cities?id=${state}`,
      method: 'get',
    }),
    country_banks: {
      url: '/banks/country_banks',
      method: 'post',
    },
    get_mobile_versions: {
      url: '/mobile_versions',
      method: 'get',
    },
    get_server_time: {
      url: '/server_time',
      method: 'get',
    },
    get_messages: {
      url: '/messages/:place',
      method: 'get',
    },
    get_country_config: {
      url: '/profile/get_country_configuration',
      method: 'get',
    },
    get_modal_information_country: (isoCode) => ({
      url: `/countries/modal_route?iso_code=${isoCode}`,
      method: 'get',
    }),
  },
  transactions: {
    getPaymentUrlFromSkrill: {
      url: '/skrill/get_recharge_url',
      method: 'post',
    },
    getPaymentUrlFromWompi: {
      url: '/wompi/get_payment_url',
      method: 'post',
    },
    getTokenFromWompi: {
      url: '/wompi/accept_token_url',
      method: 'get',
    },
    createRechargeFromWompi: {
      url: '/wompi/create_transaction',
      method: 'post',
    },
    getTransferRules: {
      url: '/transactions/transfer_rules',
      method: 'get',
    },
    getVitaCountries: {
      url: '/transactions/transfer_rules',
      method: 'get',
    },
    paymentInfo: {
      method: 'get',
      url: '/payment_gateway/info?:params',
    },
    transbankPayment: {
      method: 'post',
      url: '/payment_gateway/transbank',
    },
    createExchange: {
      url: '/transactions/exchange',
      method: 'post',
    },
    createTransfer: {
      url: '/transactions/country_withdrawal',
      method: 'post',
    },
    list: (currency) => ({
      url: `/transactions?${currency ? `currency=${currency}` : ''}`,
      method: 'get',
    }),
    filterList: (startDate, endDate, currency) => ({
      url: `/transactions?startDate=${startDate}&endDate=${endDate}&${currency ? `currency=${currency}` : ''}`,
      method: 'get',
    }),
    createWithdrawal: {
      url: '/transactions/withdrawal',
      method: 'post',
    },
    createSent: {
      url: '/transactions',
      method: 'post',
    },
    createRechargeFromWebpay: {
      url: '/transactions/transbank',
      method: 'post',
    },
    createRechargeFromBinancePay: {
      url: '/transactions/binance_pay',
      method: 'post',
    },
    createRechargeFromOneclick: {
      url: '/transactions/payoneclick',
      method: 'post',
    },
    affiliateCreditCard: {
      url: '/transactions/oneclick',
      method: 'get',
    },
    sendHistory: {
      url: '/transactions/send_history',
      method: 'post',
    },
    validate_batch_send: {
      url: `/transactions/validate_batch_send`,
      method: 'post',
    },
    create_batch_send: {
      url: `/transactions/create_batch_send`,
      method: 'post',
    },
    get_status_batch_send: {
      url: `/transactions/get_batch_send`,
      method: 'get',
    },
    update_prices_batch_send: {
      url: `/transactions/recalculate_batch_send`,
      method: 'post',
    },
  },
  powwi: {
    generateRechargeUrl: {
      url: '/powwi/generate_recharge_url',
      method: 'post',
    },
    getBanksPSE: {
      url: '/powwi/get_pse_banks',
      method: 'get',
    },
  },
  vita_card: {
    activateCard: {
      url: '/card_requests/activate',
      method: 'post',
    },
    requestVitaCard: {
      url: '/card_accounts',
      method: 'post',
    },
    renowalVitaCard: {
      url: '/card_requests/renewal',
      method: 'post',
    },
    createPin: {
      url: '/card_requests/create_pin',
      method: 'post',
    },
    showVirtualCard: (card_id) => ({
      url: `/card_requests/virtual_card?card_id=${card_id}`,
      method: 'get',
    }),
    changeCardStatus: {
      url: '/card_requests',
      method: 'put',
    },
    getCardData: {
      url: '/card_accounts/get_by_user',
      method: 'get',
    },
    changeVitaCardCurrency: {
      url: '/card_requests/update_currency',
      method: 'put',
    }
  },
  coupons: {
    getCoupons: (status) => ({
      url: `/coupon_users?status=${status}`,
      method: 'get',
    }),
    searchCoupon: (identifier) => ({
      url: `/coupons/${identifier}`,
      method: 'get',
    }),
    activeCoupon: (data) => ({
      url: `/coupon_users/activate`,
      method: 'post',
      data,
    }),
    cancelCoupon: (coupon_user_id) => ({
      url: `/coupon_users/${coupon_user_id}/cancel`,
      method: 'put',
    }),
    getCouponTransaction: (data) => ({
      url: `/coupon_users/coupon_to_use`,
      method: 'post',
      data,
    }),
  },
  paymentServices: {
    getServiceCategories: {
      url: '/services/get_categories?is_little=true',
      method: 'get',
    },
    getServices: (key, category) => ({
      url: `/services?filter=${key}${category ? `&category_id=${category}` : ''}`,
      method: 'get',
    }),
    getUserBills: {
      url: `/users/services/get_pending_balance`,
      method: 'post',
    },
    createPaymentService: {
      url: `/services/payment`,
      method: 'post',
    },
    getLastPaid: {
      url: `/users/services/last_paid`,
      method: 'get',
    },
    reSendVoucher: {
      url: '/users/services/resend_voucher',
      method: 'post'
    },
  },
  validation: {
    getValidationPin: {
      url: '/users/generate_verification_token',
      method: 'post',
    },
    confirmValidationPin: {
      url: '/users/confirm_verification_token',
      method: 'post',
    },
  },
  dynamic_forms: {
    get_form_by_type: (type) => ({
      url: `/form_types/get_by_type?form_type=${type}`,
      method: 'get',
    }),
    get_select_options: (form_field_id, dependent_option) => ({
      url: `/form_types/get_options?form_field_id=${form_field_id}&dependent_option=${dependent_option}`,
      method: 'get',
    }),
  },
  email: {
    validationEMailRegistration: {
      url: '/confirmation_by_code',
      method: 'post',
    },
    resendEmailVerification: {
      url: '/resend_mail_confirmation',
      method: 'post',
    },
  },
  crypto: {
    create_account: {
      url: '/currency_accounts',
      method: 'post',
    },
    get_networks: (params) => ({
      url: `/crypto_accounts/get_networks?${Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')}`,
      method: 'get',
    }),
    lost_funds: {
      url: '/users/send_funds_not_received_alert',
      method: 'post',
    },
  },
  avatar: {
    getAvatarsAvailable: {
      url: '/users/avatars',
      method: 'get',
    },
    setUserAvatar: {
      url: '/users/avatar/set_avatar',
      method: 'put',
    },
  },
  banners: {
    getBanners: {
      url: '/users/get_banners',
      method: 'get',
    },
  },
  snackbars: {
    getSnackbars: {
      url: '/users/get_snackbars',
      method: 'get',
    },
  },
  businessPaymentLink: {
    getBusinessPaymentLink: {
      url: '/users/business_payment_order_config',
      method: 'get',
    },
    updateBusinessPaymentLink: (data) => ({
      url: `users/business_payment_order_config`,
      method: 'put',
      data,
    }),
    getChekoutInfo: (userId, publicCode) => ({
      url: `/users/${userId}/payment_orders/${publicCode}`,
      method: 'get',
    }),
    createPaymentOrder: (userId, publicCode, data) => ({
      url: `/users/${userId}/payment_orders/${publicCode}/assign_provider`,
      method: 'post',
      data,
    }),
    getStatusBussinessPaymentLink: {
      url: `/users/get_payment_orders_service_status`,
      method: 'get',
    },
    requestServiceBusinessPaymentLink: {
      url: `/users/request_payment_orders_service`,
      method: 'post',
    },
    createNewPaymentLink: (userId, data) => ({
      url: `/users/${userId}/payment_orders`,
      method: 'post',
      data,
    }),
    getPaymentLinkHistory: (userId, params) => ({
      url: `/users/${userId}/payment_orders?${Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')}`,
      method: 'get',
    }),
  },
  usdAccount: {
    signUsdAccount: (data) => ({
      url: '/bridge/create_customer',
      method: 'post',
      data,
    }),
  },
};