import { useState, useCallback, useMemo, useEffect } from 'react';
import moment from 'moment';
import { Platform } from 'react-native';

import config from '../config';
import useAmounts from './useAmounts';
import useResidenceConfig from './useResidenceConfig';
import useBanksAccounts from './useBanksAccounts';
import useDeviceInfo from 'wallet/hooks/useDeviceInfo';
import transactionsService from '../services/transactions';
import useTransferPrices from './useTransferPrices';
import { formatMoney, waitingAction } from '../utils';
import useDisableButton from 'wallet/hooks/useDisableButton';

const useWithdrawal = (props) => {
	const {
		withdrawalAccount = null,
		startFetch = () => { },
		endFetch = () => { },
		onWithdrawalCompleted = () => { },
		keyBoard: {
			showKeyBoardReset = () => { },
		},
	} = props;
	const { useUser, useRules } = config.getInstance().getConfiguration();
	const { isClicked, handleClickedDisableButton } = useDisableButton();

	const {
		fiatCurrencies,
	} = useRules();
	const {
		user: {
			headers,
			default_currency,
			iso_code_country,
			handle_default_fiat_currency,
			balances,
		},
		updateProfile,
		userIsReady,
	} = useUser();

	const { deviceId } = useDeviceInfo();

	const { accounts, banksAccountsIsReady } = useBanksAccounts({ isGetBankAccounts: true });

	const { prices, isReadyPrices } = useTransferPrices();
	const {
		min_amount_withdrawal,
		currency_iso_code,
		currency_decimals,
		iso_code,
	} = useResidenceConfig();
	const { countryByCurrencyCode, decimalAmounts } = useAmounts();

	const receiveCountryFlagImageSource = countryByCurrencyCode(currency_iso_code).flag_url;
	const [sendCurrency, changeSendCurrency] = useState(handle_default_fiat_currency);
	const [amount, changeAmount] = useState(0);
	const [purpose, changePurpose] = useState('');
	const [purpose_key, changePurposeKey] = useState('');
	const [comentary, changeComentary] = useState('');
	const [touched, setTouched] = useState({
		amount: false,
		purpose: false,
		comentary: false,
	});
	const [commission, changeCommission] = useState(0);
	const [isAllowedTransaction, setAllowedTransaction] = useState(true);
	const [fixedCost, changeFixedCost] = useState(0);
	const [costVita, setCostVita] = useState(0);
	const [calculatedFixedCost, changeCalculatedFixedCost] = useState({
		costoComision: 0,
		costoGMF: 0,
		costoIVA: 0,
		costoTransaccion: 0,
	});
	const [isCalculatingCost, setIsCalculatingCost] = useState(false);
	const [isConfirmation, setConfirmation] = useState(false);
	const [account, changeAccount] = useState(withdrawalAccount);

	const priceValidDate = useMemo(() => (prices?.valid_until), [prices]);

	useEffect(() => {
		if (isReadyPrices) {
			const fiat = prices.fiat[iso_code_country.toLowerCase()];
			changeCommission((amount * prices.fiat_withdrawal_fee) / 100);
			changeFixedCost(fiat[`${sendCurrency.toLowerCase()}_is_apply_fixed_cost_in_withdrawal`] ? parseFloat(fiat[`${sendCurrency.toLowerCase()}_fixed_cost`]) : 0);
			setCostVita(fiat[`${sendCurrency.toLowerCase()}_is_apply_fixed_cost_in_withdrawal`] ? parseFloat(fiat[`${sendCurrency.toLowerCase()}_fixed_cost`]) : 0);
			// changeCalculatedFixedCost({
			// 	...calculatedFixedCost,
			// 	// costoTransaccion: calculatedFixedCost.costoTransaccion + (fiat.is_apply_fixed_cost_in_withdrawal ? fiat.fixed_cost : 0)
			// });
		}
	}, [
		prices,
		sendCurrency,
		isReadyPrices,
		amount,
	]);

	const { currency_decimals: sendCurrencyDecimals, flag_url, iso_code: sendCountry } = useMemo(() => countryByCurrencyCode(sendCurrency), [sendCurrency]);

	const showKeyBoardForAmount = useCallback(() => {
		showKeyBoardReset(amount, sendCurrency, iso_code_country.toLowerCase(), sendCurrencyDecimals);
	}, [amount, sendCurrency]);

	const showConfirmation = () => {
		setConfirmation(true);
	};

	const hiddenConfirmation = () => {
		setConfirmation(false);
	};

	const minimumAmount = useMemo(() => {
		let min = min_amount_withdrawal;

		if (sendCurrency.toLowerCase() !== default_currency.toLowerCase()) {
			const fiat = prices.fiat[iso_code_country.toLowerCase()];
			min = Number(min / fiat[`${sendCurrency.toLowerCase()}_sell`])
		}

		return decimalAmounts(min, sendCountry);
	}, [
		default_currency,
		prices,
		iso_code_country,
		min_amount_withdrawal,
		sendCurrency,
		sendCountry,
	]);

	const day = useMemo(() => {
		if (isReadyPrices) {
			return moment()
				.add(prices.days[iso_code_country.toLowerCase()], 'days')
				.format('LL');
		} else {
			return 0;
		}
	},
		[prices, iso_code_country, isReadyPrices]
	);

	const total = useMemo(() => (amount - commission - fixedCost), [amount, commission, fixedCost]);

	const receiveAmount = useMemo(() => {
		if (isReadyPrices) {
			const fiat = prices.fiat[iso_code.toLowerCase()];
			return (total * fiat[`${sendCurrency.toLowerCase()}_sell`]);
		}
	}, [
		sendCurrency,
		total,
		prices,
		isReadyPrices,
	]);

	const receiveAmountLabel = useMemo(() => {
		return ` ${formatMoney(
			receiveAmount,
			currency_iso_code,
			currency_decimals,
		)} ${currency_iso_code.toUpperCase()}`;
	}, [
		receiveAmount,
		currency_iso_code,
		currency_decimals,
	]);

	const realQuote = useMemo(() => {
		const fiat = prices.fiat[iso_code.toLowerCase()];
		const quote = fiat[`${sendCurrency.toLowerCase()}_sell`];

		const quotation = amount > 0 && receiveAmount > 0 ? (receiveAmount / amount) : quote;

		if (quotation > 1 || sendCurrency.toUpperCase() === currency_iso_code.toUpperCase()) {
			return ` 1 ${sendCurrency.toUpperCase()} = ${formatMoney(
				quotation,
				currency_iso_code,
				currency_decimals,
				true
			)} ${currency_iso_code.toUpperCase()}`
		} else {
			return ` 1 ${sendCurrency.toUpperCase()} = ${formatMoney(
				(1 / quotation),
				currency_iso_code,
				currency_decimals,
				true
			)} ${currency_iso_code.toUpperCase()}`
		}
	}, [
		sendCurrency,
		receiveAmount,
		amount,
		currency_iso_code,
		currency_decimals,
		sendCurrencyDecimals,
	]);

	const totalLabel = useMemo(() =>
		formatMoney(amount, sendCurrency, sendCurrencyDecimals),
		[amount, sendCurrency, sendCurrencyDecimals]
	);

	const amountMax = useMemo(() => (
		userIsReady ? balances[sendCurrency.toLowerCase()] : 0
	), [sendCurrency, balances]);

	const errors = useMemo(() => {
		return {
			comentary: comentary ?
				comentary.length < 5 ?
					'Mínimo 5 caracteres' :
					comentary.length > 50 ?
						'50 caracteres máximo' :
						'' :
				'',
		};
	}, [
		comentary,
	]);

	const isValidForm = useMemo(() => {
		if (userIsReady) {
			return !!(
				isReadyPrices &&
				banksAccountsIsReady &&
				isAllowedTransaction &&
				account &&
				amount <= balances[sendCurrency.toLowerCase()] &&
				comentary &&
				!errors.comentary &&
				purpose &&
				purpose_key &&
				amount >= minimumAmount &&
				total > 0
			);
		} else {
			return false;
		}
	}, [
		errors,
		isReadyPrices,
		userIsReady,
		purpose,
		comentary,
		amount,
		sendCurrency,
		balances,
		purpose_key,
		minimumAmount,
		account,
		isAllowedTransaction,
	]);

	const isHaveBankAccounts = useMemo(() => (
		banksAccountsIsReady ? accounts.length > 0 : false
	), [accounts, banksAccountsIsReady]);

	const isSendToPeru = useMemo(() => (
		iso_code_country.toLowerCase() === 'pe'
	), [iso_code_country]);

	const isWithdrawalReady = useMemo(() => (
		banksAccountsIsReady && isReadyPrices
	), [banksAccountsIsReady, isReadyPrices]);

	const sendCountryFlagImageSource = useMemo(() => flag_url, [flag_url]);

	const totalWithIsoCodeCurrency = useMemo(() => (
		`${totalLabel} ${sendCurrency.toUpperCase()}`
	), [totalLabel, sendCurrency]);

	const oneCurrencyToEqualsLabel = useMemo(() => (`${realQuote.split('=')[0]} =`), [realQuote]);

	const rateWithCurrencyNameLabel = useMemo(() => (`${realQuote.split('=')[1]}`), [realQuote]);

	const equivalentWithCurrencyNameLabel = useMemo(() => (receiveAmountLabel), [receiveAmountLabel]);

	const reset = () => {
		changeAmount(0);
		changePurpose('');
		changePurposeKey('');
		changeComentary('');
		changeCommission(0);
	};

	const createWithdrawal = useCallback(
		async (pin) => {
			try {
				handleClickedDisableButton();
				startFetch();
				const response = await transactionsService.createWithdrawal(
					headers,
					{
						currency_send: sendCurrency,
						amount: amount,
						purpose: purpose_key,
						purpose_comentary: comentary,
						source: Platform.OS,
						account_id: account.id,
						document_type: account.document_type,
						document_number: account.document_number,
						otp: pin,
						device_fingerprint: deviceId,
					},
				);

				const { encrypt_id } = response.data.attributes;
				await updateProfile();
				onWithdrawalCompleted(day, encrypt_id);
				endFetch();
			} catch (error) {
				handleClickedDisableButton();
				endFetch(error);
			}
		},
		[
			amount,
			headers,
			purpose_key,
			comentary,
			day,
			account,
			sendCurrency,
		],
	);

	const showValidErrores = useCallback(() => {
		setTouched({
			amount: true,
			purpose: true,
			comentary: true,
		});
	}, [setTouched]);

	return {
		errors,
		priceValidDate,
		fiatCurrencies,
		equivalentWithCurrencyNameLabel,
		rateWithCurrencyNameLabel,
		sendCountryFlagImageSource,
		receiveCountryFlagImageSource,
		realQuote,
		isWithdrawalReady,
		isHaveBankAccounts,
		banksAccountsIsReady,
		isConfirmation,
		amountMax,
		minimumAmount,
		isAllowedTransaction,
		isCalculatingCost,
		calculatedFixedCost,
		isSendToPeru,
		comentary,
		isValidForm,
		amount,
		receiveAmountLabel,
		totalLabel,
		totalWithIsoCodeCurrency,
		oneCurrencyToEqualsLabel,
		account,
		purpose,
		sendCurrency,
		changeAmount,
		changePurpose,
		changePurposeKey,
		changeAccount,
		changeComentary,
		showConfirmation,
		hiddenConfirmation,
		showKeyBoardForAmount,
		createWithdrawal,
		reset,
		sendCurrencyDecimals,
		showValidErrores,
		touched,
		isDisableButtonWithdrawal: isClicked,
		changeSendCurrency,
	};
};

export default useWithdrawal;
