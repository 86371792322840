import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import Share from 'react-native-share';
// import * as yup from 'yup';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
// import { Alert } from 'react-native';
import {
  SETTINGS_DEFAULT_VALUE,
  SETTINGS_KEY,
} from 'dumdee/state';

import { Colors } from '../resources';
import { width, height } from '../hooks/useWindowsSize';


export const networkProps = {
  pingServerUrl: 'https://www.vitawallet.io/',
  pingInterval: 30000,
  pingOnlyIfOffline: true,
};

export const showAlert = (error) => { alert(error) };

export const isValidateIp = (ip) => {
  return (
    ip.match(/^(?=.*[^.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).?){4}/g) !==
    null
  );
};

// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

const scale = (size) => (width / guidelineBaseWidth) * size;
const verticalScale = (size) => (height / guidelineBaseHeight) * size;

/* eslint-disable no-console */
const log = (name, data) => {
  // console.group(name);
  if (data) {
    // console.log(data);
  }
  // console.groupEnd();
};
/* eslint-enable no-console */

export {
  log,
  scale,
  verticalScale,
};

// export const prepareHeaders = (
//   params = {
//     token: '',
//     client_id: '',
//     uid: '',
//     'access-token-mobile': '',
//   },
// ) => {
//   const {client_id, uid, token} = params;
//   if (params['access-token-mobile']) {
//     return {
//       'access-token-mobile': params['access-token-mobile'],
//       client: '',
//       uid,
//     };
//   } else {
//     return {
//       'access-token': token,
//       client: client_id,
//       uid,
//     };
//   }
// };

export const isActiveMoney = (category) => {
  return (
    category === 'deposit' ||
    category === 'received' ||
    category === 'exchange' ||
    category === 'payment'
  );
};

export const prepareUser = (response) => {
  const user = response.data;
  let headers = response.headers;
  /*
  if (headers.hasOwnProperty('expiry')) {
    headers.expiry = moment().add(1, 'minutes').unix().toString();
  }

  if (headers.hasOwnProperty('expire-token-mobile')) {
    headers['expire-token-mobile'] = moment()
      .add(1, 'minutes')
      .unix()
      .toString();
  }
  */
  return {
    ...user,
    headers,
  };
};

export const getTimeDifference = (expiryTime, format) => {
  const timeLeft = moment.unix(expiryTime).diff(moment().toDate().getTime());
  return moment(timeLeft).format(format);
};

export const IOPeripherals = {
  QRSelect: 'IOPeripherals/QRSelect',
  CountrySelect: 'IOPeripherals/CountrySelect',
  CurrencySelect: 'IOPeripherals/CurrencySelect',
  NumberKeyboard: 'IOPeripherals/NumberKeyboard',
};

// export const isUserExpirySession = (user, minutes = 0) => {
//   const now = moment().toDate().getTime();
//   const expiryTime = moment
//     .unix(
//       user.headers['expire-token-mobile']
//         ? user.headers['expire-token-mobile']
//         : user.headers.expiry,
//     )
//     .subtract(minutes, 'minute')
//     .toDate()
//     .getTime();
//   return expiryTime < now;
// };

export const isoCodeCountries = {
  Argentina: 'ar',
  Bolivia: 'bo',
  Chile: 'cl',
  Brasil: 'br',
  Colombia: 'co',
  Ecuador: 'ec',
  México: 'mx',
  Panamá: 'pa',
  Paraguay: 'py',
  Perú: 'pe',
  'Perú (USD)': 'peusd',
  Uruguay: 'uy',
  Venezuela: 've',
  'Estados Unidos': 'us',
  'Estados Unidos - WIRE': 'uswires',
  'Reino Unido': 'gb',
  'China': 'cn',
  'Costa Rica': 'cr',
  'República Dominicana': 'do',
  'Haití': 'ht',
};

export const isoCodeCountriesCurrencies = {
  ar: 'ARS',
  bo: 'BOB',
  cl: 'CLP',
  bra: 'BRL',
  co: 'COP',
  ec: 'USD',
  mx: 'MXN',
  pa: 'PAB',
  py: 'PYG',
  pe: 'PEN',
  peusd: 'USD',
  uy: 'UYU',
  ve: 'VES',
  us: 'USD',
  uswires: 'USD',
  gb: 'GBP',
  cn: 'CNY',
  cr: 'CRC',
  do: 'DOP',
  ht: 'USD',
};

export const currencyCountries = {
  Argentina: 'ARS',
  Bolivia: 'BOB',
  Chile: 'CLP',
  Brasil: 'BRL',
  Colombia: 'COP',
  Ecuador: 'USD',
  México: 'MXN',
  Panamá: 'PAB',
  Paraguay: 'PYG',
  Perú: 'PEN',
  'Perú (USD)': 'USD',
  Uruguay: 'UYU',
  Venezuela: 'VES',
  'Estados Unidos': 'USD',
  'Estados Unidos - WIRE': 'USD',
  'Reino Unido': 'GBP',
  'China': 'CNY',
  'Costa Rica': 'CRC',
  'República Dominicana': 'DOP',
  'Haití': 'USD',
};

export const regEx = {
  allPoints: /\./g,
  chileanRut: /^0*(\d{1,3}(\.\d{3})*)-?([\dkK])$/,
  dateDDMMYYYY: /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  btcWallet: /(?:[a-zA-Z1-9]{24,70})/g,
  btcWalletFromVitaWithinAmount: /bitcoin:(?:[a-zA-Z1-9]{24,70})\?amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])(([0-9][0-9][0-9])*)(\.([0-9]*))/g,
  btcWalletFromVita: /bitcoin:(?:[a-zA-Z1-9]{24,70})/g,
  btcWalletFromVitaWithinAmountAndDescription: /bitcoin:(?:[a-zA-Z1-9]{24,70})\?amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])(([0-9][0-9][0-9])*)(\.([0-9]*))\?description=((.)*)/g,
  btcWalletFromVitaWithinDescription: /bitcoin:(?:[a-zA-Z1-9]{24,70})\?description=((.)*)/g,
  fiatAddressFromVita: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))/g,
  fiatAddressFromVitaWithinAmountAndDescription: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))&amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])((\.([0-9][0-9][0-9]))*)(,([0-9]{2}))&description=((.)*)/g,
  fiatAddressFromVitaWithinAmount: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))&amount=([0-9]|[0-9][0-9]|[0-9][0-9][0-9])((\.([0-9][0-9][0-9]))*)(,([0-9]{2}))/g,
  fiatAddressFromVitaWithinDescription: /&email=((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))&description=((.)*)/g,
};

export const rutMask = (value = '', country = '', document_type_key = '') => {
  if (
    normalize(country).toLowerCase() === 'chile' &&
    document_type_key !== 'passport'
  ) {
    value = value.replace(/\./g, '').replace('-', '');

    if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
      value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
      value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, '$1.$2.$3-$4');
    } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
      value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, '$1.$2.$3');
    } else if (value.match(/^(\d)(\d{0,2})$/)) {
      value = value.replace(/^(\d)(\d{0,2})$/, '$1.$2');
    }

    if (
      value.length > 0 &&
      (value[value.length - 1] === '.' || value[value.length - 1] === '-')
    ) {
      value = value.slice(0, value.length - 1);
    }
  }

  return value;
};

export const formatRut = (rut) => {
  const newRut = rut.replace(/\./g, '').replace(/-/g, '').trim().toLowerCase();
  const lastDigit = newRut.substr(-1, 1);
  const rutDigit = newRut.substr(0, newRut.length - 1)
  let format = '';
  for (let i = rutDigit.length; i > 0; i--) {
    const e = rutDigit.charAt(i - 1);
    format = e.concat(format);
    if (i % 3 === 0) {
      format = '.'.concat(format);
    }
  }
  return format.concat('-').concat(lastDigit);
}

// export const documentsYup = {
//   cl: yup
//     .string()
//     .matches(
//       // /^0*(\d{1,3}(\.\d{3})*)\-?([\dkK])$/,
//       /^[0-9]+[\dkK]$/,
//       'Ingrese sin puntos y guiones',
//     )
//     .min(5, 'Ingrese sin puntos y guiones'),
//   /*.test(
//       'document_number',
//       'Formato inválido, ejemplo: 11.111.111-1/11.111.111-K',
//       (value) => {
//         return value.length > 3
//           ? /^0*(\d{1,3}(\.\d{3})*)\-?([\dkK])$/.test(value)
//           : false;
//       },
//     ),*/
//   mx: yup
//     .string()
//     .matches(/^[0-9]+$/, 'Solo números')
//     .min(6, 'Mínimo 6 digitos')
//     .max(22, 'Máximo 22 digitos'),
//   all: yup
//     .string()
//     .matches(/^[0-9]+$/, 'Solo números')
//     .min(6, 'Mínimo 6 digitos')
//     .max(15, 'Máximo 15 digitos'),
//   allCompanyId: yup
//     .string()
//     .min(6, 'Mínimo 6 digitos')
//     .max(15, 'Máximo 15 digitos'),
//   allDni: yup.string().min(6, 'Mínimo 6 digitos').max(15, 'Máximo 15 digitos'),
//   passport: yup
//     .string()
//     .min(6, 'Mínimo 6 digitos')
//     .max(11, 'Máximo 11 digitos'),
// };

export const isValidEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
};

export const isValidBtcWallet = (wallet) => {
  return /(?:[a-zA-Z1-9]{24,70})/g.test(wallet);
};

export const isValidDateDDMMYYYY = (date) => {
  return /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(
    date,
  );
};

export const share = async (
  value,
  title = 'Comparte desde tus redes sociales',
) => {
  try {
    /*await Share.open({
      message: value,
      title,
    });*/
  } catch (error) { }
};

export const documentTypesResolve = [
  {
    label: 'Documento de identidad',
    value: 'dni',
  },
  {
    label: 'Documento de identidad',
    value: 'DNI',
  },
  {
    label: 'Documento de empresa',
    value: 'company_id',
  },
  {
    label: 'Pasaporte',
    value: 'passport',
  },
  {
    value: 'CURPE',
    label: 'Clave única de registro de población de empresas',
  },
  {
    value: 'ID',
    label: 'Documento de identificación de empresas',
  },
  {
    label: 'Clave única de registro de población',
    value: 'CURP',
  },
  {
    label: 'Número de identificación tributaria',
    value: 'NIT',
  },
  {
    label: 'Clave única de registro de población',
    value: 'CURP',
  },
  {
    label: 'Cédula de ciudadanía',
    value: 'CC',
  },
  {
    value: 'RUC',
    label: 'Registro único de contribuyentes',
  },
  {
    label: 'Carnet de extranjería',
    value: 'CE',
  },
  {
    label: 'Pasaporte',
    value: 'PASS',
  },
];

export const documentTypes = [
  {
    label: 'Documento de identidad',
    value: 'dni',
  },
  {
    label: 'Pasaporte',
    value: 'passport',
  },
];

export const civilStatusOptions = [
  {
    label: 'Soltero',
    value: 'single',
  },
  {
    label: 'Casado',
    value: 'married',
  },
  {
    label: 'Divorciado',
    value: 'divorced',
  },
  {
    label: 'Separado',
    value: 'separated',
  },
  {
    label: 'Viudo',
    value: 'widowed',
  },
];

export const genderOptions = [
  {
    label: 'Femenino',
    value: 'female',
  },
  {
    label: 'Masculino',
    value: 'male',
  },
  {
    label: 'Otro',
    value: 'other',
  },
];

export const resolveGender = (value) => {
  let label = '';
  genderOptions.forEach((type) => {
    if (type.value === value) {
      label = type.label;
    }
  });
  return label;
};

export const resolveCivilStatus = (value) => {
  let label = '';
  civilStatusOptions.forEach((type) => {
    if (type.value === value) {
      label = type.label;
    }
  });
  return label;
};

export const resolveDocumentTypeBusiness = (value) => {
  let label = '';
  documentTypes.forEach((type) => {
    if (type.value === value) {
      label = type.label;
    }
  });
  return label;
};

export const resolveDocumentType = (value) => {
  let label = '';
  documentTypesResolve.forEach((type) => {
    if (value !== null && type.value.toLowerCase() === value.toLowerCase()) {
      label = type.label;
    }
  });
  return label;
};

export const videoConstraints = {
  width: 1280,
  height: 720,
};

export const addPushNotificationFromCache = (addPushNotification) => {
  // eslint-disable-next-line no-undef
  if (window && window.caches) {
    caches.has('fcm-1').then(function (exist) {
      if (exist) {
        // eslint-disable-next-line no-undef
        caches.open('fcm-1').then(function (cache) {
          cache.match('/fcm').then((settings) => {
            if (settings && settings.body) {
              const reader = settings.body.getReader();
              reader.read().then(function processText({ done, value }) {
                // eslint-disable-next-line no-undef
                const notifications = JSON.parse(
                  // eslint-disable-next-line no-undef
                  new TextDecoder('utf-8').decode(value),
                );
                notifications.forEach((notification) => {
                  addPushNotification(notification);
                });
              });
            }
            cache.delete('/fcm');
          });
          // eslint-disable-next-line no-undef
          caches.delete('fcm-1');
        });
      }
    });
  }
};

export const addPushNotification = async (remoteMessage) => {
  try {
    const settings =
      JSON.parse(await AsyncStorage.getItem(SETTINGS_KEY)) ||
      SETTINGS_DEFAULT_VALUE;

    if (remoteMessage.hasOwnProperty('data')) {
      const data = remoteMessage.data.hasOwnProperty('object_type')
        ? JSON.parse(remoteMessage.data.object_data)
        : remoteMessage.data;

      const notification = {
        ...data,
        notification_type: remoteMessage.data.hasOwnProperty('object_type')
          ? remoteMessage.data.object_type
          : 'promotion',
        sentTime: remoteMessage.sentTime,
      };

      await AsyncStorage.setItem(
        SETTINGS_KEY,
        JSON.stringify({
          ...settings,
          pushNotifications: [
            ...settings.pushNotifications,
            { ...notification, isRead: false },
          ],
        }),
      );
    }
  } catch (error) { }
};

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

export const capitalizeFullName = (fullName) => {
  fullName = fullName.toLowerCase();
  const tokens = fullName.split(' ');
  const first = tokens[0][0].toUpperCase() + tokens[0].slice(1);
  const second = tokens[1]
    ? tokens[1][0].toUpperCase() + tokens[1].slice(1)
    : '';
  const newName = `${first} ${second}`;
  return newName.trim();
};

export const onlyTwoWordsFromFullName = (firstName, lastName) => {
  firstName = firstName.toLowerCase();
  lastName = lastName.toLowerCase();
  firstName = firstName.split(' ')[0];
  lastName = lastName.split(' ')[0];
  firstName = firstName[0].toUpperCase() + firstName.slice(1);
  lastName = lastName[0].toUpperCase() + lastName.slice(1);
  return `${firstName} ${lastName}`;
};

export const toWithBottomBar = async () => {
  try {
    await changeNavigationBarColor(Colors.white, true);
  } catch (e) { }
};

export const toDarkBottomBar = async () => {
  try {
    await changeNavigationBarColor(Colors.gray_dark);
  } catch (e) { }
};

export const normalize = (function () {
  var from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç',
    to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc',
    mapping = {};

  for (var i = 0, j = from.length; i < j; i++) {
    mapping[from.charAt(i)] = to.charAt(i);
  }

  return function (str) {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) {
        ret.push(mapping[c]);
      } else {
        ret.push(c);
      }
    }
    return ret.join('');
  };
})();

export const countryPhoneCodes = [
  {
    code: '+7 840',
    name: 'Abkhazia',
  },
  {
    code: '+93',
    name: 'Afghanistan',
  },
  {
    code: '+355',
    name: 'Albania',
  },
  {
    code: '+213',
    name: 'Algeria',
  },
  {
    code: '+1 684',
    name: 'American Samoa',
  },
  {
    code: '+376',
    name: 'Andorra',
  },
  {
    code: '+244',
    name: 'Angola',
  },
  {
    code: '+1 264',
    name: 'Anguilla',
  },
  {
    code: '+1 268',
    name: 'Antigua and Barbuda',
  },
  {
    code: '+54',
    name: 'Argentina',
  },
  {
    code: '+374',
    name: 'Armenia',
  },
  {
    code: '+297',
    name: 'Aruba',
  },
  {
    code: '+247',
    name: 'Ascension',
  },
  {
    code: '+61',
    name: 'Australia',
  },
  {
    code: '+672',
    name: 'Australian External Territories',
  },
  {
    code: '+43',
    name: 'Austria',
  },
  {
    code: '+994',
    name: 'Azerbaijan',
  },
  {
    code: '+1 242',
    name: 'Bahamas',
  },
  {
    code: '+973',
    name: 'Bahrain',
  },
  {
    code: '+880',
    name: 'Bangladesh',
  },
  {
    code: '+1 246',
    name: 'Barbados',
  },
  {
    code: '+1 268',
    name: 'Barbuda',
  },
  {
    code: '+375',
    name: 'Belarus',
  },
  {
    code: '+32',
    name: 'Belgium',
  },
  {
    code: '+501',
    name: 'Belize',
  },
  {
    code: '+229',
    name: 'Benin',
  },
  {
    code: '+1 441',
    name: 'Bermuda',
  },
  {
    code: '+975',
    name: 'Bhutan',
  },
  {
    code: '+591',
    name: 'Bolivia',
  },
  {
    code: '+387',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: '+267',
    name: 'Botswana',
  },
  {
    code: '+55',
    name: 'Brasil',
  },
  {
    code: '+246',
    name: 'British Indian Ocean Territory',
  },
  {
    code: '+1 284',
    name: 'British Virgin Islands',
  },
  {
    code: '+673',
    name: 'Brunei',
  },
  {
    code: '+359',
    name: 'Bulgaria',
  },
  {
    code: '+226',
    name: 'Burkina Faso',
  },
  {
    code: '+257',
    name: 'Burundi',
  },
  {
    code: '+855',
    name: 'Cambodia',
  },
  {
    code: '+237',
    name: 'Cameroon',
  },
  {
    code: '+1',
    name: 'Canada',
  },
  {
    code: '+238',
    name: 'Cape Verde',
  },
  {
    code: '+1 345',
    name: 'Cayman Islands',
  },
  {
    code: '+236',
    name: 'Central African Republic',
  },
  {
    code: '+235',
    name: 'Chad',
  },
  {
    code: '+56',
    name: 'Chile',
  },
  {
    code: '+86',
    name: 'China',
  },
  {
    code: '+61',
    name: 'Christmas Island',
  },
  {
    code: '+61',
    name: 'Cocos-Keeling Islands',
  },
  {
    code: '+57',
    name: 'Colombia',
  },
  {
    code: '+269',
    name: 'Comoros',
  },
  {
    code: '+242',
    name: 'Congo',
  },
  {
    code: '+243',
    name: 'Congo, Dem. Rep. of (Zaire)',
  },
  {
    code: '+682',
    name: 'Cook Islands',
  },
  {
    code: '+506',
    name: 'Costa Rica',
  },
  {
    code: '+385',
    name: 'Croatia',
  },
  {
    code: '+53',
    name: 'Cuba',
  },
  {
    code: '+599',
    name: 'Curacao',
  },
  {
    code: '+357',
    name: 'Cyprus',
  },
  {
    code: '+420',
    name: 'Czech Republic',
  },
  {
    code: '+45',
    name: 'Denmark',
  },
  {
    code: '+246',
    name: 'Diego Garcia',
  },
  {
    code: '+253',
    name: 'Djibouti',
  },
  {
    code: '+1 767',
    name: 'Dominica',
  },
  {
    code: '+1 809',
    name: 'Dominican Republic',
  },
  {
    code: '+670',
    name: 'East Timor',
  },
  {
    code: '+56',
    name: 'Easter Island',
  },
  {
    code: '+593',
    name: 'Ecuador',
  },
  {
    code: '+20',
    name: 'Egypt',
  },
  {
    code: '+503',
    name: 'El Salvador',
  },
  {
    code: '+240',
    name: 'Equatorial Guinea',
  },
  {
    code: '+291',
    name: 'Eritrea',
  },
  {
    code: '+372',
    name: 'Estonia',
  },
  {
    code: '+251',
    name: 'Ethiopia',
  },
  {
    code: '+500',
    name: 'Falkland Islands',
  },
  {
    code: '+298',
    name: 'Faroe Islands',
  },
  {
    code: '+679',
    name: 'Fiji',
  },
  {
    code: '+358',
    name: 'Finland',
  },
  {
    code: '+33',
    name: 'France',
  },
  {
    code: '+596',
    name: 'French Antilles',
  },
  {
    code: '+594',
    name: 'French Guiana',
  },
  {
    code: '+689',
    name: 'French Polynesia',
  },
  {
    code: '+241',
    name: 'Gabon',
  },
  {
    code: '+220',
    name: 'Gambia',
  },
  {
    code: '+995',
    name: 'Georgia',
  },
  {
    code: '+49',
    name: 'Germany',
  },
  {
    code: '+233',
    name: 'Ghana',
  },
  {
    code: '+350',
    name: 'Gibraltar',
  },
  {
    code: '+30',
    name: 'Greece',
  },
  {
    code: '+299',
    name: 'Greenland',
  },
  {
    code: '+1 473',
    name: 'Grenada',
  },
  {
    code: '+590',
    name: 'Guadeloupe',
  },
  {
    code: '+1 671',
    name: 'Guam',
  },
  {
    code: '+502',
    name: 'Guatemala',
  },
  {
    code: '+224',
    name: 'Guinea',
  },
  {
    code: '+245',
    name: 'Guinea-Bissau',
  },
  {
    code: '+592',
    name: 'Guyana',
  },
  {
    code: '+509',
    name: 'Haiti',
  },
  {
    code: '+504',
    name: 'Honduras',
  },
  {
    code: '+852',
    name: 'Hong Kong SAR China',
  },
  {
    code: '+36',
    name: 'Hungary',
  },
  {
    code: '+354',
    name: 'Iceland',
  },
  {
    code: '+91',
    name: 'India',
  },
  {
    code: '+62',
    name: 'Indonesia',
  },
  {
    code: '+98',
    name: 'Iran',
  },
  {
    code: '+964',
    name: 'Iraq',
  },
  {
    code: '+353',
    name: 'Ireland',
  },
  {
    code: '+972',
    name: 'Israel',
  },
  {
    code: '+39',
    name: 'Italy',
  },
  {
    code: '+225',
    name: 'Ivory Coast',
  },
  {
    code: '+1 876',
    name: 'Jamaica',
  },
  {
    code: '+81',
    name: 'Japan',
  },
  {
    code: '+962',
    name: 'Jordan',
  },
  {
    code: '+7',
    name: 'Kazakhstan',
  },
  {
    code: '+254',
    name: 'Kenya',
  },
  {
    code: '+686',
    name: 'Kiribati',
  },
  {
    code: '+965',
    name: 'Kuwait',
  },
  {
    code: '+996',
    name: 'Kyrgyzstan',
  },
  {
    code: '+856',
    name: 'Laos',
  },
  {
    code: '+371',
    name: 'Latvia',
  },
  {
    code: '+961',
    name: 'Lebanon',
  },
  {
    code: '+266',
    name: 'Lesotho',
  },
  {
    code: '+231',
    name: 'Liberia',
  },
  {
    code: '+218',
    name: 'Libya',
  },
  {
    code: '+423',
    name: 'Liechtenstein',
  },
  {
    code: '+370',
    name: 'Lithuania',
  },
  {
    code: '+352',
    name: 'Luxembourg',
  },
  {
    code: '+853',
    name: 'Macau SAR China',
  },
  {
    code: '+389',
    name: 'Macedonia',
  },
  {
    code: '+261',
    name: 'Madagascar',
  },
  {
    code: '+265',
    name: 'Malawi',
  },
  {
    code: '+60',
    name: 'Malaysia',
  },
  {
    code: '+960',
    name: 'Maldives',
  },
  {
    code: '+223',
    name: 'Mali',
  },
  {
    code: '+356',
    name: 'Malta',
  },
  {
    code: '+692',
    name: 'Marshall Islands',
  },
  {
    code: '+596',
    name: 'Martinique',
  },
  {
    code: '+222',
    name: 'Mauritania',
  },
  {
    code: '+230',
    name: 'Mauritius',
  },
  {
    code: '+262',
    name: 'Mayotte',
  },
  {
    code: '+52',
    name: 'Mexico',
  },
  {
    code: '+691',
    name: 'Micronesia',
  },
  {
    code: '+1 808',
    name: 'Midway Island',
  },
  {
    code: '+373',
    name: 'Moldova',
  },
  {
    code: '+377',
    name: 'Monaco',
  },
  {
    code: '+976',
    name: 'Mongolia',
  },
  {
    code: '+382',
    name: 'Montenegro',
  },
  {
    code: '+1 664',
    name: 'Montserrat',
  },
  {
    code: '+212',
    name: 'Morocco',
  },
  {
    code: '+95',
    name: 'Myanmar',
  },
  {
    code: '+264',
    name: 'Namibia',
  },
  {
    code: '+674',
    name: 'Nauru',
  },
  {
    code: '+977',
    name: 'Nepal',
  },
  {
    code: '+31',
    name: 'Netherlands',
  },
  {
    code: '+599',
    name: 'Netherlands Antilles',
  },
  {
    code: '+1 869',
    name: 'Nevis',
  },
  {
    code: '+687',
    name: 'New Caledonia',
  },
  {
    code: '+64',
    name: 'New Zealand',
  },
  {
    code: '+505',
    name: 'Nicaragua',
  },
  {
    code: '+227',
    name: 'Niger',
  },
  {
    code: '+234',
    name: 'Nigeria',
  },
  {
    code: '+683',
    name: 'Niue',
  },
  {
    code: '+672',
    name: 'Norfolk Island',
  },
  {
    code: '+850',
    name: 'North Korea',
  },
  {
    code: '+1 670',
    name: 'Northern Mariana Islands',
  },
  {
    code: '+47',
    name: 'Norway',
  },
  {
    code: '+968',
    name: 'Oman',
  },
  {
    code: '+92',
    name: 'Pakistan',
  },
  {
    code: '+680',
    name: 'Palau',
  },
  {
    code: '+970',
    name: 'Palestinian Territory',
  },
  {
    code: '+507',
    name: 'Panamá',
  },
  {
    code: '+675',
    name: 'Papua New Guinea',
  },
  {
    code: '+595',
    name: 'Paraguay',
  },
  {
    code: '+51',
    name: 'Peru',
  },
  {
    code: '+63',
    name: 'Philippines',
  },
  {
    code: '+48',
    name: 'Poland',
  },
  {
    code: '+351',
    name: 'Portugal',
  },
  {
    code: '+1 787',
    name: 'Puerto Rico',
  },
  {
    code: '+974',
    name: 'Qatar',
  },
  {
    code: '+262',
    name: 'Reunion',
  },
  {
    code: '+40',
    name: 'Romania',
  },
  {
    code: '+7',
    name: 'Russia',
  },
  {
    code: '+250',
    name: 'Rwanda',
  },
  {
    code: '+685',
    name: 'Samoa',
  },
  {
    code: '+378',
    name: 'San Marino',
  },
  {
    code: '+966',
    name: 'Saudi Arabia',
  },
  {
    code: '+221',
    name: 'Senegal',
  },
  {
    code: '+381',
    name: 'Serbia',
  },
  {
    code: '+248',
    name: 'Seychelles',
  },
  {
    code: '+232',
    name: 'Sierra Leone',
  },
  {
    code: '+65',
    name: 'Singapore',
  },
  {
    code: '+421',
    name: 'Slovakia',
  },
  {
    code: '+386',
    name: 'Slovenia',
  },
  {
    code: '+677',
    name: 'Solomon Islands',
  },
  {
    code: '+27',
    name: 'South Africa',
  },
  {
    code: '+500',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: '+82',
    name: 'South Korea',
  },
  {
    code: '+34',
    name: 'Spain',
  },
  {
    code: '+94',
    name: 'Sri Lanka',
  },
  {
    code: '+249',
    name: 'Sudan',
  },
  {
    code: '+597',
    name: 'Suriname',
  },
  {
    code: '+268',
    name: 'Swaziland',
  },
  {
    code: '+46',
    name: 'Sweden',
  },
  {
    code: '+41',
    name: 'Switzerland',
  },
  {
    code: '+963',
    name: 'Syria',
  },
  {
    code: '+886',
    name: 'Taiwan',
  },
  {
    code: '+992',
    name: 'Tajikistan',
  },
  {
    code: '+255',
    name: 'Tanzania',
  },
  {
    code: '+66',
    name: 'Thailand',
  },
  {
    code: '+670',
    name: 'Timor Leste',
  },
  {
    code: '+228',
    name: 'Togo',
  },
  {
    code: '+690',
    name: 'Tokelau',
  },
  {
    code: '+676',
    name: 'Tonga',
  },
  {
    code: '+1 868',
    name: 'Trinidad and Tobago',
  },
  {
    code: '+216',
    name: 'Tunisia',
  },
  {
    code: '+90',
    name: 'Turkey',
  },
  {
    code: '+993',
    name: 'Turkmenistan',
  },
  {
    code: '+1 649',
    name: 'Turks and Caicos Islands',
  },
  {
    code: '+688',
    name: 'Tuvalu',
  },
  {
    code: '+1 340',
    name: 'U.S. Virgin Islands',
  },
  {
    code: '+256',
    name: 'Uganda',
  },
  {
    code: '+380',
    name: 'Ukraine',
  },
  {
    code: '+971',
    name: 'United Arab Emirates',
  },
  {
    code: '+44',
    name: 'United Kingdom',
  },
  {
    code: '+1',
    name: 'United States',
  },
  {
    code: '+598',
    name: 'Uruguay',
  },
  {
    code: '+998',
    name: 'Uzbekistan',
  },
  {
    code: '+678',
    name: 'Vanuatu',
  },
  {
    code: '+58',
    name: 'Venezuela',
  },
  {
    code: '+84',
    name: 'Vietnam',
  },
  {
    code: '+1 808',
    name: 'Wake Island',
  },
  {
    code: '+681',
    name: 'Wallis and Futuna',
  },
  {
    code: '+967',
    name: 'Yemen',
  },
  {
    code: '+260',
    name: 'Zambia',
  },
  {
    code: '+255',
    name: 'Zanzibar',
  },
  {
    code: '+263',
    name: 'Zimbabwe',
  },
];

export const phoneCodeFromCountry = (country) => {
  let code = '';
  countryPhoneCodes.forEach((codeItem) => {
    if (
      normalize(codeItem.name.toLowerCase()) ===
      normalize(country.toLowerCase())
    ) {
      code = codeItem.code;
    }
  });
  return code;
};

export const titleNavigationManager = (scene) => {
  switch (scene) {
    case 'Integration': {
      return 'Vita Business';
    }
    case 'Exchange': {
      return 'Crypto';
    }
    case 'Receive': {
      return 'Recibir';
    }
    case 'Recharge': {
      return 'Recarga Vita Wallet';
    }
    case 'AccountBank': {
      return 'Tus cuentas bancarias';
    }
    case 'PersonalInformation': {
      return 'Tu información personal';
    }
    case 'VerificationStatus': {
      return 'Estado de verificación';
    }
    case 'security': {
      return 'Seguridad';
    }
    case 'PushNotifications': {
      return 'Notificaciones';
    }
    case 'CryptoSettings': {
      return 'Configuración Crypto';
    }
    case 'Sent': {
      return 'Envía dinero';
    }
    case 'AccountActivator': {
      return 'Verificar cuenta';
    }
    default: {
      return 'Vita Wallet';
    }
  }
};
